import { observer } from 'mobx-react-lite'
import { useMatch, useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { usePage } from 'features/datasets'
import { t } from '@lingui/macro'

export const ButtonMarkup = observer(() => {
  const page = usePage()
  const match = useMatch('/project/:id/*')
  const navigate = useNavigate()
  const href = `${match?.pathnameBase}/markup`

  return (
    <Button
      href={href}
      disabled={!page.hasMarkup}
      onClick={(e) => {
        e.preventDefault()
        navigate(href)
      }}
    >
      {t`Markup`}
    </Button>
  )
})
