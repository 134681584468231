import { styled } from '@mui/material'
import { createCell, createTemplate, mergeSX } from 'utils'
import { Category, Toggle, Action } from './buttons'
import { Color, Name, Desc } from './fields'
import { Head, ParentRow, ChildRow } from './checkboxes'
import { Count } from './count'
import { Id } from './id'
import { t } from '@lingui/macro'

const Span = styled('span')(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
}))

const head = [
  createCell(<Head />, { width: 60 }),
  createCell(t`Color`, { placeContent: 'center', width: 50 }),
  createCell(null, { placeContent: 'center', width: 60 }),
  createCell(<Span>{t`Title`}</Span>, {
    placeItems: 'center stretch',
    width: '1fr',
  }),
  createCell(t`Catalog`, { placeContent: 'center end', width: 80 }),
  createCell(null, { placeItems: 'center stretch', width: 50 }),
  createCell(<Span>{t`Description`}</Span>, {
    placeItems: 'center stretch',
    width: '1fr',
  }),
  createCell('ID', { placeItems: 'center', width: 60 }),
  createCell(null, { placeContent: 'center end', width: 60 }),
]

const template = createTemplate(head)

const parent = [
  createCell(<ParentRow />),
  createCell(<Color />),
  createCell(<Toggle />),
  createCell(<Name />),
  createCell(<Category />),
  createCell(<Count />),
  createCell(<Desc />),
  createCell(<Id />),
  createCell(<Action />),
]

const child = [
  createCell(null),
  createCell(<ChildRow />),
  createCell(<Color />),
  createCell(<Name />),
  createCell(null),
  createCell(null),
  createCell(<Desc />),
  createCell(<Id />),
  createCell(<Action />),
]

mergeSX(head, parent)
mergeSX(head, child)

export { head, template, parent, child }
