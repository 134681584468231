import { t } from '@lingui/macro'
import { action } from 'mobx'
import { Config } from 'types'
import {
  category,
  dataset,
  enableSelection,
  JsonView,
  jsonView,
  loading,
  patch,
  validationError,
} from './store'

export const setConfig = action((config: Config) => {
  const ds = dataset.get()
  if (ds) ds.config = config
})

export const toggleCategory = action(() => category.set(!category.get()))

export const toggleEnableSelection = action(() =>
  enableSelection.set(!enableSelection.get())
)

export const stopLoading = action(() => {
  loading.set(false)
})

export const clear = action(() => {
  loading.set(true)
  dataset.set(null)
})

export const setPatch = action((bool: boolean) => {
  if (bool) {
    validationError.set('')
  }
  patch.set(bool)
})

export const setJsonView = action((view: JsonView) => jsonView.set(view))

export const setDatasetParametr = action((key: string, value: string) => {
  const ds = dataset.get()
  if (ds) {
    switch (key) {
      case 'name':
        ds.name = value;
        break;
    
      case 'number_of_markups':
        ds.number_of_markups = +value
        break;

      case 'priority':
        ds.priority = +value
        break;
      
      case 'markup_duration':
        ds.markup_duration = value
        break;
      
      default:
        break;
    }

    if (!ds.name) {
      validationError.set(t`EnterDatasetName`)
    } else {
      validationError.set('')
    }
  }
})
