import { observer } from 'mobx-react-lite'
import { Typography, TextField, Stack, Skeleton } from '@mui/material'

import { TextMaskTimer } from './text-mask-timer'
import { dataset, loading, setDatasetParametr } from 'features/settings-dataset'
import { t } from '@lingui/macro'


export const ExtraSettings = observer(() => {
  const datasetData = dataset.get()
  if (loading.get()) return (
    <>
      <Typography
        children={t`DatasetSettings`}
        variant="subtitle1"
        mt={2}
        mb={1}
        mx={0}
      />
      <Skeleton variant={'button'} />
      <Skeleton variant={'button'} />
      <Skeleton variant={'button'} />
      <Skeleton variant={'button'} />
    </>
  )

  return (
    <>
      <Stack flex={1} gap={2}>    
        <Typography
          children={t`DatasetSettings`}
          variant="subtitle1"
          mt={2}
          mb={1}
          mx={0}
        />
        <TextField
          label={t`DatasetName`}
          name="name"
          value={datasetData?.name || ''}
          onChange={(event) => setDatasetParametr('name', event.target.value)}
          error={!datasetData?.name}
        />
        <TextField
          label={t`MaximumMarkupTime`}
          name="markup_duration"
          value={datasetData?.markup_duration || '00:15:00'}
          onChange={(event) => setDatasetParametr('markup_duration', event.target.value)}
          InputProps={{
            inputComponent: TextMaskTimer as any,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label={t`NumOfMarkups`}
          name="number_of_markups"
          type="number"
          value={datasetData?.number_of_markups || 0}
          onChange={(event) => setDatasetParametr('number_of_markups', event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label={t`Priority`}
          name="priority"
          type="number"
          value={datasetData?.priority || 0}
          onChange={(event) => setDatasetParametr('priority', event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Stack>
    </>
  )
})
