import { observer } from 'mobx-react-lite'
import { Stack, TextField } from '@mui/material'
import { usePage } from 'features/page-datasets-settings'
import { action } from 'mobx'
import { renderMenuItems } from 'utils'
import { t } from '@lingui/macro'

const useConfig = () => usePage().config

const TypeSelect = observer(() => {
  const c = useConfig()

  return (
    <TextField
      label={t`DataType`}
      select
      value={c.dataType}
      onChange={action(({ target }) => {
        c.dataType = target.value
      })}
      children={renderMenuItems(['JSON_PATH', 'FILE_OBJECT'])}
    />
  )
})

const ValueSelect = observer(() => {
  const c = useConfig()
  const page = usePage()

  return (
    <TextField
      label={t`DataField`}
      select
      value={c.dataValue}
      onChange={action(({ target }) => {
        c.dataValue = target.value
      })}
      children={renderMenuItems(['file'].concat(page.recordKyes))}
    />
  )
})

const CatalogIdSelect = observer(() => {
  const c = useConfig()

  if (!c.entity) return null

  return (
    <TextField
      select
      label={t`CategoriesCatalog`}
      value={c.catalogId ?? ''}
      onChange={action(({ target }) => {
        c.catalogId = +target.value
      })}
      children={renderMenuItems([])}
    />
  )
})

export const WhatMarkup = observer(() => {
  return (
    <Stack px={3} py={2} gap={2}>
      <TypeSelect />
      <ValueSelect />
      <CatalogIdSelect />
    </Stack>
  )
})
