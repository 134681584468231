import { observer } from 'mobx-react-lite'
import { Paper, Stack, Typography } from '@mui/material'
import { useProp } from 'features/settings-dataset'
import { EditBtn } from './buttons'
import { t } from '@lingui/macro'

const CatalogName = observer(() => {
  const prop = useProp()

  if (!prop.catalogName) return null

  return (
    <Typography
      children={prop.catalogName}
      sx={{
        ':not(:empty) + span': {
          color: 'text.secondary',
          '::before': { content: '"(id: "' },
          '::after': { content: '")"' },
        },
      }}
    />
  )
})
const ClassKey = observer(() => (
  <span>
    {useProp().classKey === 'ROOT'
      ? t`EntireObject`
      : useProp().classKey === 'COMMON'
      ? t`Entity`
      : useProp().classKey}
  </span>
))
const PropKey = observer(() => <span>{useProp().propKey}</span>)

export const PropCard = observer(() => (
  <Paper variant={'property'} sx={{ '--color': useProp().catalogColor }}>
    <Stack direction={'row'} gap={1}>
      <CatalogName />
      <ClassKey />
    </Stack>
    <PropKey />
    <EditBtn />
  </Paper>
))
