import * as React from 'react'
import * as Mui from '@mui/material'
import * as Shared from 'shared'
import { t } from '@lingui/macro'

export const Blocks = Mui.styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  gap: theme.spacing(3),
  margin: theme.spacing(0, -2, -2),
  padding: theme.spacing(0, 2, 2),
  overflow: 'auto',
}))

export const Block = Mui.styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  margin: theme.spacing(-2),
  padding: theme.spacing(2),
  overflow: 'auto',
  position: 'relative',
}))

export const BlockLoader = React.memo(() => (
  <Block>
    <Mui.CircularProgress sx={{ m: 'auto' }} />
  </Block>
))

export const Title = Mui.styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  margin: 0,
  color: theme.palette.text.disabled,
}))

export const Subtitle = Mui.styled('h4')(({ theme }) => ({
  ...theme.typography.subtitle1,
  margin: theme.spacing(1.5, 3),
}))

export const SubtitleRow = Mui.styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  padding: theme.spacing(1, 3),

  h4: { margin: 0 },
}))

export const CollapseButton = Mui.styled(Mui.ButtonBase)(({ theme }) => ({
  ...theme.typography.subtitle1,
  padding: theme.spacing(1.5, 3),
  display: 'flex',
  justifyContent: 'start',
  gap: theme.spacing(1),
  transition: theme.transitions.create('background'),
  borderBottom: `1px dashed ${theme.palette.divider}`,

  ':hover,:focus': {
    background: theme.palette.action.hover,
  },
}))

export const CollapseIcon = Mui.styled(Shared.Icon)(({ theme }) => ({
  color: theme.palette.text.disabled,
  transform: 'rotate(var(--rotate, -90deg))',
  transition: theme.transitions.create('transform'),

  '&[data-is-open="true"]': {
    '--rotate': 0,
  },
}))

export const Collapse = Mui.styled(Mui.Collapse)(({ theme }) => ({
  borderBottom: `1px dashed ${theme.palette.divider}`,
  overflow: 'auto',
}))

export const Paper = Mui.styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: theme.shadows[1],
  borderRadius: +theme.shape.borderRadius * 1.5,
  background: theme.palette.background.paper,
  overflow: 'auto',
  position: 'relative',
}))

export const Pre = Mui.styled('pre')(({ theme }) => ({
  font: 'inherit',
  color: theme.palette.text.secondary,
  margin: theme.spacing(3, 0),
  padding: theme.spacing(0, 3),
  overflow: 'auto',
  whiteSpace: 'pre-wrap',
}))

export const Divider = Mui.styled('hr')(({ theme }) => ({
  width: '100%',
  border: 0,
  margin: 0,
  borderTop: '1px dashed',
  borderColor: theme.palette.divider,
}))

export const RadioGroup = Mui.styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
}))

export const Label = Mui.styled('label')(({ theme }) => ({
  display: 'inline-grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  gap: theme.spacing(1),
  whiteSpace: 'nowrap',
  cursor: 'pointer',
}))

export const LabelSkeleton = () => (
  <Label>
    <Mui.Skeleton variant={'checkbox'} />
    <Mui.Skeleton sx={{ placeSelf: 'stretch' }} />
  </Label>
)

export const ScrollBlock = Mui.styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(1, 3),
  overflow: 'auto',
  gridArea: '1 / 1',
  background: theme.palette.background.paper,
}))

export const PropertyContainer = Mui.styled('div')(({ theme }) => ({
  '--hidden': 0,
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridAutoRows: 'minmax(24px, auto)',
  alignItems: 'center',
  gap: theme.spacing(0, 2),
  padding: theme.spacing(1.5),
  background: theme.palette.action.hover,
  borderRadius: theme.shape.borderRadius,
  wordBreak: 'break-word',

  ':where(:hover,:focus-within)': {
    '--hidden': 1,
  },

  '> span': {
    display: 'contents',

    '::before': {
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
      gridColumn: 1,
    },

    ':nth-of-type(1)::before': {
      content: `${t`Class`}`,
    },

    ':nth-of-type(2)::before': {
      content: `${t`Property`}`,
    },
  },

  '> button': {
    position: 'absolute',
    placeSelf: 'start end',
  },
}))
