import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Checkbox, FormControlLabel, Stack } from '@mui/material'
import { usePage } from 'features/page-datasets-settings'
import { t } from '@lingui/macro'

const useConfig = () => usePage().config

const Categor = observer(() => {
  const c = useConfig()

  return (
    <Checkbox
      checked={c.category}
      onChange={action(() => (c.category = !c.category))}
    />
  )
})

const Entity = observer(() => {
  const c = useConfig()

  return (
    <Checkbox
      checked={c.entity}
      onChange={action(() => (c.entity = !c.entity))}
    />
  )
})

export const Checkboxes = observer(() => (
  <Stack mx={3} my={1}>
    <FormControlLabel label={t`Categorization`} control={<Categor />} />
    <FormControlLabel label={t`EntitySelection`} control={<Entity />} />
  </Stack>
))
