import { observer } from 'mobx-react-lite'
import { TextField } from '@mui/material'
import { useProject } from 'features/projects'
import { t } from '@lingui/macro'

export const Field = observer<{ field: 'name' | 'description' }>(
  ({ field }) => {
    const project = useProject()

    return (
      <TextField
        autoFocus={field === 'name'}
        label={field === 'name' ? (
          t`ProjectNamePlaceholder`
        ) : (
          t`ProjectDescPlaceholder`
        )}
        value={project[field]}
        onChange={({ target }) => project.onChange({ [field]: target.value })}
      />
    )
  }
)
