import axios from "axios"
import { mapValues } from 'lodash'
import * as Mui from '@mui/material'
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import * as uuid from 'uuid'
import { toJS } from "mobx"

import { DOMEN } from "api"
import * as Shared from 'shared'
import { token } from "features/token"
import { useMarkup } from "features/page-markup"
import { Choositor, Select, Selector, utils } from "markers/selectors"
import { AsyncLoader } from "markers/async-loader/async-loader"

import markupProccess from '../markup/showers/state'
import { t } from "@lingui/macro"


export const LoadMarkup = observer(() => {
  const { datasetId, markupId } = useParams()
  const [record, setRecord] = useState<any>()
  const [markup, setMarkup] = useState<any>()
  const [dataset, setDataset] = useState<any>()
  const [categories, setCategories] = useState<Category[]>([])

  const clear = () => {
    setRecord(undefined)
    setMarkup(undefined)
    setDataset(undefined)
    setCategories([])
    markupProccess.clear()
  }

  useEffect(() => {
    axios
    .get(`${DOMEN}/api/audio_markups/${markupId}/`, { headers: token.headers })
    .then(res => {
      if (res.statusText === 'OK' && res?.data) {
        setMarkup(res.data)
        axios
        .get(`${DOMEN}/${res.data.record.split('/').slice(3).join('/')}`, { headers: token.headers })
        .then(res => res.statusText === 'OK' && res?.data && setRecord(res.data))
      }
    })
    axios
    .get(`${DOMEN}/api/audio_dataset/${datasetId}/`, { headers: token.headers })
    .then(res => res.statusText === 'OK' && res?.data && setDataset(res.data))
    axios
    .get(`${DOMEN}/api/audio_category/?page_size=100000`, { headers: token.headers })
    .then(res => res.statusText === 'OK' && res?.data && setCategories(res.data.results.map((cat: any) => ({
        pk: Number(cat.id),
        name: cat.name,
        color: cat.color,
        description: cat.description,
        examples: cat.examples,
        parent: cat.parent && {
          pk: cat.parent.split('/').slice(-2, -1)[0]
        },
      }))))
      return () => clear()
  }, [markupId])

  useEffect(() => {
    if (markup && categories) {
      markupProccess.setDefaultCategory(class_values[0])
      const propertiesROOT = markup.data.entities.find((ent: any) => ent.class.class_id === "ROOT")?.properties
      markupProccess.setRootSelections(mapValues(propertiesROOT, (o: any) => o.value))

      const common = markup.data.entities.filter((ent: any) => !isNaN(+ent.class.class_id))
      markupProccess.setCommonSelections(
        common.map((com: any) => ({
          uuid: uuid.v4(),
          selection: com.selection,
          category: categories.find(cat => cat.pk === Number(com.class.class_id)),
          properties: mapValues(com.properties, (o: any) => o.value),
        }))
      )
    }
  }, [markup, categories])

  const onChangeHandle = (sel: SelectT) => markupProccess.modifySelection(sel)
	const onChangeMagic = (selects: SelectT[]) =>
    markupProccess.modifySelectionMagic(selects)
  const onChangeChoositor = (propertyKey: string, value: Value) => {
    markupProccess.setRootSelection(propertyKey, value)
  }
  const handler = (element: SelectionT, type: string, value?: Value) => {
    markupProccess.onSelectionChange(
      element,
      type,
      categories,
      value,
    )
  }

  const onChangeDefaultClass = (pk: Value) => markupProccess.setDefaultCategory(
    utils.getCategory(categories || [], Number(pk))
  )

  const class_values = utils.categoriesFromParent(categories, utils.classId(dataset?.config?.markup_components))

  const onConfirm = () => {
    const markupForSave = {
      "entities": [
      {
        class: { "class_id": "ROOT" },
        selection: {},
        settings: {
          isVisible: true
        },
        properties: Object.assign({}, ...Object.entries(toJS(markupProccess.rootSelection)).map(ent => ({[ent[0]]: {value: ent[1]}}))),
      },
      ...toJS(markupProccess.selections).map(sel => ({
        class: {
          class_id: String(sel.category?.pk),
          value: sel.category?.name,
        },
        settings: {
          isVisible: true
        },
        selection: sel.selection,
        properties: Object.assign(
          {},
          ...Object.entries(toJS(sel.properties)).map(ent => ({
            [ent[0]]: {
              value: ent[1],
            },
          }))),
      }))
    ]}
    axios.patch(`${DOMEN}/api/audio_markups/${markupId}/`, {data: markupForSave}, { headers: token.headers })
    .then(res => console.log(res))
  }

  const getData = () => {
    const type = dataset?.config?.display_component?.data?.type
    switch (type) {
      case 'FILE_OBJECT': {
        return `${DOMEN}/media/${record.id}/`
      }
      case 'JSON_PATH': {
        return record.data.text || ''
      }
      default: return ''
    }
  }

  return markup && dataset && record ? (
    <Shared.Page>
      <Shared.PageTitleProject />
      <Mui.Paper sx={{ flex: 1, display: 'flex', overflow: 'hidden' }}>
        <Mui.Stack flex={1} gap={1} p={3} position={'relative'}>
          <AsyncLoader
            headers={token.headers}
            component={dataset.config.display_component.type}
            data={getData()}
            selections={markupProccess.selections.map(sel => sel.selection)}
            onChange={onChangeHandle}
						onChangeMagic={onChangeMagic}
						defaultCategoryPk={markupProccess.defaultCategory?.pk}
						datasetId={Number(atob(String(useMarkup().task?.dataset?.id)).split(':')[1])}
          />
        </Mui.Stack>
        <Mui.Divider orientation={'vertical'} />
        <Mui.Stack flexBasis={340} gap={3} mt={3} mb={2}>
          <Mui.Stack px={3} gap={3}>
          <Mui.Button
            children={t`Confirm`}
            variant={'contained'}
            onClick={onConfirm}
          />
            <Mui.Paper elevation={3}>
              <Select
                values={class_values}
                onChange={onChangeDefaultClass}
                value={markupProccess.defaultCategory?.pk || 0}
              />
            </Mui.Paper>
          </Mui.Stack>
          <Mui.Divider />
          <Mui.Stack px={3} gap={3} overflow="scroll">
            <Mui.Paper elevation={1}>
              {utils.properties(dataset.config.markup_components, 'ROOT').map(elem => {
                let value
                const entries = Object.entries(markupProccess.rootSelection)
                const ent = entries.find(ent => ent[0] === elem[0])
                if (ent) value = ent[1]
                return (
                  <Choositor
                    key={elem[1].label}
                    property={elem[1]}
                    categories={categories}
                    onChange={(value) => onChangeChoositor(elem[0], value)}
                    value={value}
                  />
                )
              })}
            </Mui.Paper>  
            <Mui.Stack gap={2}>
              <Selector
                markupComponents={dataset.config.markup_components}
                categories={categories}
                onChange={handler}
                markups={markupProccess.selections}
              />
            </Mui.Stack>
          </Mui.Stack>
        </Mui.Stack>
      </Mui.Paper>
    </Shared.Page>
  ) : <Mui.CircularProgress />
})

export default LoadMarkup
