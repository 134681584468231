import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
} from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Order, Markup } from '../utils/type'
import { getComparator } from '../utils/utils'
import { MarkupsTableHeader, NotFoundMarkups } from '../molecules'
import { t } from '@lingui/macro'


export type MarkupsTableProps = {
  markups: Markup[]
}

export const MarkupsTable = ({ markups }: MarkupsTableProps) => {
  const navigate = useNavigate()
  const [orderBy, setOrderBy] = useState('role')
  const [order, setOrder] = useState<Order>('desc')

  const handleSort = (property: string) => {
    setOrder(orderBy === property && order === 'asc' ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const getColor = (value: boolean) => {
    switch (value) {
      case true:
        return 'warning.main'
      case false:
        return 'primary.main'
      default:
        return 'text.primary'
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 750 }}>
        <MarkupsTableHeader
          order={order}
          orderBy={orderBy}
          onSort={handleSort}
        />

        <TableBody>
          {markups.length ? (
            markups.sort(getComparator(order, orderBy)).map((markup) => {
              return (
                <TableRow
                  hover
                  key={markup.id}
                  onClick={() => navigate(`${markup.id}`)}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                  }}
                >
                  <TableCell align="left" sx={{paddingLeft: '14px'}}>
                    <Typography>{markup.id}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>{markup.user}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>
                      {markup.is_honeypot ? t`Honeypot` : t`Markup`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography color={getColor(markup.is_skipped)}>
                      {markup.is_skipped ? t`Yes` : t`No`}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {Number(markup.df_match_ratio) || 0}
                  </TableCell>
                  <TableCell align="center">
                    <Typography color={getColor(markup.is_skipped)}>
                      {markup.time}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{markup.created_at}</TableCell>
                </TableRow>
              )
            })
          ) : (
            <NotFoundMarkups />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
