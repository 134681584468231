import { useState } from "react"
import { Box, Button, Switch, Typography } from "@mui/material"

import { FactorInput } from "../atoms/factor-input"
import { t } from "@lingui/macro"


type FactorInputProps = React.ComponentProps<typeof FactorInput>

type Props = {
  title?: string
  max: FactorInputProps['max']
  onSubmit: (
    value: FactorInputProps['value'],
    isFactor: FactorInputProps['isFactor']
  ) => void
}

export const CreateHoneypotDataset = ({ title, max, onSubmit }: Props) => {
  const [isFactor, setIsFactor] = useState(false)
  const [value, setValue] = useState(0)

  const switchHandle = () => {
    setIsFactor(prev => !prev)
    setValue(0)
  }

  const create = () => {
    onSubmit(isFactor ? value / 100 : value, isFactor)
    setValue(0)
  }

  return (
    <Box m={1}>
      <Typography textAlign="center" variant="subtitle1" children={title} />
      <Box display="flex">
        <FactorInput {...{max, value, isFactor}} onChange={setValue} />
        <Switch
          onChange={switchHandle}
          sx={{
            '.MuiSwitch-switchBase': { color: '#00897b' },
            '.MuiSwitch-track': { backgroundColor: '#00897b' },
          }}
        />
        <Button
          onClick={create}
          disabled={!value || !max}
          children={t`Create`}
          variant="contained"
          />
      </Box>
    </Box>
  )
}
