import { t } from '@lingui/macro'
import { Button, Stack } from '@mui/material'

import { StyledRedButton } from '../../styled-red-button'


type CancelNextButtonsProps = {
  onClick: (value: string) => void
}

export const CancelNextButtons = ({ onClick }: CancelNextButtonsProps) => (
  <Stack direction={'row'} gap={1} justifyContent={'center'}>
    <StyledRedButton
      children={t`Reject`}
      variant={'contained'}
      onClick={() => onClick('reject')}
    />
    <Button
      children={t`Next`}
      variant={'contained'}
      onClick={() => onClick('next')}
    />
  </Stack>
)
