import { Observer, observer } from 'mobx-react-lite'
import { Box, Skeleton, CircularProgress } from '@mui/material'
import { useToggle, Toggle } from 'shared'
import { toJsonString } from 'utils'
import { usePage } from 'features/page-datasets-settings'
import { Pre, Block, Paper, Title, Divider } from '../atoms'
import { t } from '@lingui/macro'

interface Props {
  toggle: ReturnType<typeof useToggle>
}

const Json = observer<Props>(({ toggle }) => {
  const page = usePage()

  if (page.isLoading)
    return (
      <Box p={3}>
        {Array(10)
          .fill(null)
          .map((_, i) => (
            <Skeleton key={i} />
          ))}
      </Box>
    )

  if (toggle.active) return <Pre children={toJsonString(page.record?.data)} />

  // if (page.errorMsg)
  //   return (
  //     <Pre
  //       sx={{ color: 'error.main' }}
  //       children={toJsonString(page.errorMsg)}
  //     />
  //   )

  return <Pre children={page.config.json} />
})

const PatchLoader = observer(() => {
  const page = usePage()

  return page.fetch?.method === 'PATCH' ? (
    <CircularProgress
      size={20}
      sx={{ position: 'absolute', top: 50, right: 10 }}
    />
  ) : null
})

export const BlockJson = observer(() => {
  const toggle = useToggle()
  const page = usePage()

  return (
    <Block>
      <Title>{t`Extra`}</Title>
      <Paper>
        <Observer>
          {() => (
            <Toggle
              toggle={toggle}
              labels={[t`Configuration`, t`Data`]}
              disabled={page.isLoading}
            />
          )}
        </Observer>
        <Divider />
        <Json toggle={toggle} />
        <PatchLoader />
      </Paper>
    </Block>
  )
})
