import { t } from '@lingui/macro'
import { Box } from '@mui/material'

import { StyledGreenButton } from '../../styled-green-button'


type ReasonButtonsProps = {
  onChangeReason: (value: string) => void
}

export const ReasonButtons = ({ onChangeReason }: ReasonButtonsProps) => (
  <Box>
    <StyledGreenButton
      variant={'contained'}
      sx={{ mb: 1 }}
      onClick={() => onChangeReason(t`PoorQuality`)}
    >
      {t`PoorQuality`}
    </StyledGreenButton>
    <StyledGreenButton
      variant={'contained'}
      sx={{ mb: 1 }}
      onClick={() => onChangeReason(t`NotFollowingInstructions`)}
    >
      {t`NotFollowingInstructions`}
    </StyledGreenButton>
    <StyledGreenButton
      variant={'contained'}
      sx={{ mb: 1 }}
      onClick={() => onChangeReason(t`NewErrorType`)}
    >
      {t`NewErrorType`}
    </StyledGreenButton>
  </Box>
)
