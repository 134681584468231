import axios from 'axios'
import { DOMEN } from 'api'
import { makeAutoObservable } from 'mobx'

import { token } from 'features/token'
import { t } from '@lingui/macro'


type RolesOnProject = {
  id: number
  role: string
  user: string
  project: string
}

type RolesUserOnProjects = {
  id: number
  roleId: number
  userId: number
  projectId: number
}

type Roles = {
  id: number
  pk: number
  url: string
  codename: string
  name: string
  type: number
  permissions: string[]
}

export type User = {
  id: number
  url: string
  role_set: string[]
  last_login: string
  is_superuser: boolean
  username: string
  first_name: string
  last_name: string
  email: string
  is_staff: boolean
  is_active: boolean
  date_joined: string
  groups: any[]
  checked: boolean
  rating?: number
  newRole?: number
  modify?: boolean
}

export class UsersState {
  users: User[] = []
  roles: Roles[] = []
  rolesUserProject: RolesUserOnProjects[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setUsers(users: any[]) {
    this.users = users.map((user) => ({
      ...user,
      checked: false,
      rating: user.profile?.rating,
    }))
  }

  setRoles(roles: any[]) {
    this.roles = [
      ...roles.map((role) => ({
        ...role,
        pk: role.id,
      })),
      { pk: 0, name: t`RoleNotAssigned` },
    ]
  }

  modifyRolesUserProject(rolesOnProject: RolesOnProject) {
    return {
      ...rolesOnProject,
      id: rolesOnProject.id,
      roleId: Number(rolesOnProject.role.split('/').slice(-2, -1)[0]),
      userId: Number(rolesOnProject.user.split('/').slice(-2, -1)[0]),
      projectId: Number(rolesOnProject.project.split('/').slice(-2, -1)[0]),
    }
  }

  setModifyRolesUserProject(rolesOnProjects: RolesOnProject[]) {
    this.rolesUserProject = rolesOnProjects.map((rop: any) =>
      this.modifyRolesUserProject(rop)
    )
  }

  setRolesUserProject(rolesUserOnProjects: RolesUserOnProjects[]) {
    this.rolesUserProject = rolesUserOnProjects
  }

  onCheckToggle(userId: number) {
    const user = this.users.find((user) => user.id === userId)
    if (user) user.checked = !user.checked
  }

  onCreateOrEditRole(
    value: number,
    userId: number,
    id: number | undefined,
    projectId?: number
  ) {
    const formData = new FormData()
    formData.append('user', `${DOMEN}/api/users/${userId}/`)
    formData.append('role', `${DOMEN}/api/context_role/${value}/`)
    formData.append('project', `${DOMEN}/api/projects/${projectId}/`)
    if (id) {
      axios
        .put(`${DOMEN}/api/role_user_project/${id}/`, formData, {
          headers: token.headers,
        })
        .then((res) =>
          this.setRolesUserProject(
            this.rolesUserProject.map((el) =>
              el.id === id
                ? {
                    ...el,
                    roleId: Number(res.data.role.split('/').slice(-2, -1)[0]),
                  }
                : el
            )
          )
        )
    } else {
      axios
        .post(`${DOMEN}/api/role_user_project/`, formData, {
          headers: token.headers,
        })
        .then((res) =>
          this.setRolesUserProject([...this.rolesUserProject, this.modifyRolesUserProject(res.data)])
        )
    }
  }

  onDeleteRole(id: number) {
    axios
      .delete(`${DOMEN}/api/role_user_project/${id}/`, {
        headers: token.headers,
      })
      .then(() =>
        this.setRolesUserProject([
          ...this.rolesUserProject.filter((el) => el.id !== id),
        ])
      )
  }

  onUserChange(
    userId: number,
    key: 'rating' | 'newRole',
    value: number,
    oldRole?: number,
    projectId?: number
  ) {
    if (key === 'newRole' && oldRole !== value) {
      const id = this.rolesUserProject.filter(
        (el) =>
          el.userId === userId &&
          el.roleId === oldRole &&
          el.projectId === projectId
      )[0]?.id
      if (Boolean(value)) {
        this.onCreateOrEditRole(value, userId, id, projectId)
      } else {
        this.onDeleteRole(id)
      }
    }
  }

  wasSaved() {
    this.users.forEach((user) => (user.modify = false))
  }

  clear() {
    this.users = []
    this.roles = []
    this.rolesUserProject = []
  }
}

export default new UsersState()
