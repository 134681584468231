import { observer } from 'mobx-react-lite'
import {
  Collapse,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material'

import { useProject } from 'features/projects'
import { Page } from 'shared'

import { dataset, useGetData, usePatch } from 'features/settings-dataset'
import { DataBtn, ErrorBtn, JsonBtn, SaveBtn } from './buttons'
import { RecordTextSelection } from './record-text-checkboxes'
import { Categorization, EntitySelection } from './checkboxes'
import { CatalogClasses } from './catalog-classes'
import { Properties } from './properties'
import { DataValue } from './data-value'
import { ExtraSettings } from './extra-settings'
import { PageTitle } from './page-title'
import { DataType } from './data-type'
import { Json } from './json'
import { getDataset } from 'features/settings-dataset/fetch'
import { t } from '@lingui/macro'


const Blocks = styled('div')(({ theme }) => ({
  flex: 1,
  margin: theme.spacing(-2),
  padding: theme.spacing(2),
  overflow: 'hidden',
  display: 'grid',
  gridTemplate: `
    "t1 t2 t3" auto
    "b1 b2 b3 " 1fr / 1fr 1fr 1fr
  `,
  gap: theme.spacing(2, 3),
}))


export const SettingsDataset = observer(() => {
  const project = useProject()
  useGetData()
  usePatch()
  return (
    <Page>
      <PageTitle />
      <Blocks>
        <Typography
          children={t`HowToMarkup`}
          variant={'h2'}
          color={'text.disabled'}
          gridArea={`t1`}
        />
        <Typography
          children={t`GeneralSettings`}
          variant={'h2'}
          color={'text.disabled'}
          gridArea={`t2`}
        />
        <Typography
          children={t`Extra`}
          variant={'h2'}
          color={'text.disabled'}
          gridArea={`t3`}
        />
        <Paper
          variant={'block'}
          children={
            <>
              <Typography
                variant={'subtitle1'}
                children={t`HowToMarkup`}
                mx={3}
                my={2}
              />
              <Divider />
              <Stack mx={3} my={1}>
                <RecordTextSelection projectType={project.type} />
                <FormControlLabel
                  label={t`Categorization`}
                  control={<Categorization />}
                />
                <FormControlLabel
                  label={t`EntitySelection`}
                  control={<EntitySelection />}
                />
              </Stack>
              <Divider />
              <Properties />
            </>
          }
          sx={{ gridArea: `b${1}` }}
        />
        <Paper
          variant={'block'}
          children={
            <>
              <Typography
                variant={'subtitle1'}
                children={t`WhatToMarkup`}
                mx={3}
                my={2}
              />
              <Divider />
              <Collapse in>
                <Stack mx={3} py={2} gap={2}>
                  <DataType />
                  <DataValue />
                  <CatalogClasses />
                  <ExtraSettings />
                </Stack>
              </Collapse>
            </>
          }
          sx={{ gridArea: `b${2}}` }}
        />
        <Paper
          variant={'block'}
          children={
            <>
              <Stack direction={'row'} gap={1} mx={2} my={1}>
                <SaveBtn />
                <JsonBtn />
                <DataBtn />
                <ErrorBtn />
              </Stack>
              <Divider />
              <Json />
            </>
          }
          sx={{ gridArea: `b${3}}` }}
        />
      </Blocks>
    </Page>
  )
})
