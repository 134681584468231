import { t } from '@lingui/macro'
import { TextField } from '@mui/material'
import { NumericFormat } from 'react-number-format'


type InputSecondsProps = {
  seconds: number
  onChange: (value: string) => void
  slice: boolean
}

const style = {
  width: '150px',
  '& input': {
    textAlign: 'center',
  },
  fontSize: '12px',
  boxSizing: 'border-box',
  borderRadius: '4px',
  '&.Mui-disabled': {
    backgroundColor: '#E2E5E9',
    color: '#2B313B',
  },
} as const

export const InputSeconds = ({
  seconds,
  onChange,
  slice,
}: InputSecondsProps) => {

  const integerCheck = (inputObj: any) => {
    const { value } = inputObj
    if (!value.includes('.')) return true
    return false
  }

  return (
    <NumericFormat
      sx={style}
      onValueChange={({ value }) => onChange(value)}
      value={seconds}
      customInput={TextField}
      disabled={!slice}
      allowNegative={false}
      isAllowed={integerCheck}
      helperText={slice && !Boolean(seconds) ? t`IncorrectLength` : ''}
      error={slice && !Boolean(seconds)}
    />
  )
}
