import { t } from '@lingui/macro'
import { MenuItem } from '@mui/material'

import { StyledTextField } from '../styled-textfield'


type UserFilterProps = {
  values: any[]
  value: string | boolean
  onSelect: (value: any, name: string) => void
}

export const UserFilter = ({ values, value, onSelect }: UserFilterProps) => (
  <StyledTextField
    select
    fullWidth
    name={'user'}
    value={value}
    label={t`User`}
    onChange={(evt) => onSelect(evt.target.value, 'user')}
  >
    {values.map((el, ind) => (
      <MenuItem key={ind + 1} value={el}>
        {el}
      </MenuItem>
    ))}
  </StyledTextField>
)
