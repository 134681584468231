import { ChangeEvent } from 'react'
import { observer } from 'mobx-react-lite'
import { MenuItemProps } from '@mui/material'
import { styled, TextField, MenuItem, Stack } from '@mui/material'

import {
  useProp,
  propertyClassItems as classItems,
  // propertyTypeItems as typeItems,
  rootCatalogItems as sourceItems,
} from 'features/settings-dataset'

import { CloseBtn, DoneBtn, RemoveBtn } from './buttons'
import { t } from '@lingui/macro'


const Form = styled('form')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 0, 2),
}))

const ClassKey = observer(() => {
  const prop = useProp()

  if (!prop.isNew) return null

  return (
    <TextField
      select
      autoFocus
      label={t`ObjectClass`}
      value={prop.classKey}
      onChange={(e) => prop.setClassKey(e.target.value)}
    >
      {classItems.get().map((i) => (
        <MenuItem key={i.value as string} {...i} />
      ))}
    </TextField>
  )
})

const Label = observer(() => {
  const prop = useProp()

  return (
    <TextField
      label={t`Title`}
      value={prop.label}
      onChange={(e) => prop.setLabel(e.target.value)}
    />
  )
})

const Type = observer(() => {
  const prop = useProp()
  const propertyTypeItems: MenuItemProps[] = [
    { value: 'CHECK_BOX', children: t`Checkbox` },
    { value: 'COMBO_BOX', children: t`Select` },
    { value: 'INPUT_BOX_TEXT', children: t`Input` },
    { value: 'AUDIO_RECORD', children: t`MicrophoneRecording` },
  ]

  return (
    <TextField
      select
      label={t`Type`}
      value={prop.type}
      onChange={(e) => prop.setType(e.target.value)}
    >
      {propertyTypeItems.map((i) => (
        <MenuItem key={i.value as string} {...i} />
      ))}
    </TextField>
  )
})

const PropKey = observer(() => {
  const prop = useProp()

  const onChangeHandle = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const text = e.target.value
    if (text.match(/^[a-zA-Z0-9]+$/gi) || text === '') prop.setPropKey(text)
  }

  if (!prop.isNew) return null

  return (
    <TextField
      label={t`DataField`}
      value={prop.propKey}
      onChange={onChangeHandle}
      error={prop.formError}
      helperText={
        prop.formError ? `${t`ClassContainsProperty`} ${prop.propKey}` : ''
      }
      FormHelperTextProps={{ sx: { position: 'absolute', top: '100%' } }}
    />
  )
})

export const Source = observer(() => {
  const prop = useProp()

  if (prop.showSelect)
    return (
      <TextField
        select
        label={t`SourceOfValues`}
        value={prop.catalogId || ''}
        onChange={(e) => prop.setCatalogId(e.target.value)}
      >
        {sourceItems.get().map((i) => (
          <MenuItem key={i.value as string} {...i} />
        ))}
      </TextField>
    )

  return null
})

export const PropForm = observer(() => {
  const prop = useProp()

  if (!prop.showForm) return null

  return (
    <Form id={'prop-form'} onSubmit={(e) => e.preventDefault()}>
      <Stack direction={'row'} justifyContent={'end'} gap={1}>
        <RemoveBtn />
        <DoneBtn />
        <CloseBtn />
      </Stack>
      <ClassKey />
      <Label />
      <Type />
      <PropKey />
      <Source />
    </Form>
  )
})
