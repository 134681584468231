import { useEffect } from 'react'
import { action, computed, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Button, IconButton } from '@mui/material'
import { Icon } from 'shared'
import { useConfig, useItem } from 'features/page-datasets-settings'
import { t } from '@lingui/macro'

export const New = observer(() => {
  const config = useConfig()
  const item = useItem()

  useEffect(() => {
    const disp = reaction(
      () => [!config.entity, !config.category].every(Boolean),
      (close) => close && runInAction(() => (config.item = {}))
    )

    return () => disp()
  }, [config])

  if (item.classKey) return null

  return (
    <Button
      children={t`NewProperty`}
      disabled={!config.category && !config.entity}
      onClick={action(() => (config.item = { classKey: config.classKeys[0] }))}
    />
  )
})

export const Edit = observer(() => {
  const config = useConfig()
  const item = useItem()

  return (
    <IconButton
      data-hidden
      children={<Icon icon={'edit'} />}
      onClick={action(() => (config.item = item))}
    />
  )
})

export const Close = observer(() => {
  const config = useConfig()
  const item = useItem()

  if (!item.classKey) return null

  return (
    <IconButton
      children={<Icon icon={'close'} />}
      onClick={action(() => (config.item = {}))}
    />
  )
})

export const Delete = observer(() => {
  const c = useConfig()

  if (!c.item.propKey) return null

  return (
    <IconButton
      children={<Icon icon={'remove'} />}
      onClick={action(() => {
        c.arr.replace(
          c.arr.filter(({ propKey: pKey }) => pKey !== c.item?.propKey)
        )
        c.item = {}
      })}
    />
  )
})

export const Submit = observer<{ formId: string }>((props) => {
  const c = useConfig()

  if (!c.item.propKey) return null

  const disabled = computed(() => {
    const { classKey: cKey, propKey: pKey, label, type } = c.item

    return [!cKey, !pKey, !label, !type].some(Boolean)
  }).get()

  return (
    <IconButton
      form={props.formId}
      type={'submit'}
      children={<Icon icon={'done'} />}
      color={'primary'}
      disabled={disabled}
      onClick={action(() => {
        console.log(c.item.order)
      })}
    />
  )
})
