import { observable, ObservableMap } from 'mobx'
import { MenuItemProps } from '@mui/material'
import { RecordItem, DatasetItem, CatalogItem } from 'types'
import { Prop } from './prop'
import { t } from '@lingui/macro'

export const loading = observable.box(true)

export const patch = observable.box(false)

export const order = observable.box(0)

export const dataset = observable.box<DatasetItem | null>(null)

export const validationError = observable.box('')

export const record = observable.box<RecordItem | null>(null)

export const catalogs = observable.map<number, CatalogItem>()

export const classes = observable.map<string, ObservableMap<string, Prop>>()

export const category = observable.box(false)

export const enableSelection = observable.box(false)

export const catalogId = observable.box('')

export const dataType = observable.box('')

export const dataValue = observable.box('')

export type JsonView = 'settings' | 'data' | 'error'

export const jsonView = observable.box<JsonView>('settings')

export const propertyTypeItems: MenuItemProps[] = [
  { value: 'CHECK_BOX', children: t`Checkbox` },
  { value: 'COMBO_BOX', children: t`Select` },
  { value: 'INPUT_BOX_TEXT', children: t`Input` },
  { value: 'AUDIO_RECORD', children: t`MicrophoneRecording` },
]
