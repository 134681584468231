import { t } from '@lingui/macro'
import { action, observable } from 'mobx'
import { observer, Observer } from 'mobx-react-lite'
import { CollapseProps } from '@mui/material'
import { Paper, Collapse, CollapseButton, CollapseIcon } from '../atoms'
import { WhatMarkup } from './what-markup'

const collapses = observable.array<CollapseProps>([
  { in: false, title: t`WhatToMarkup`, children: <WhatMarkup /> },
  { in: false, title: 'Элементы интерфейса' },
  { in: false, title: 'Инструкция разметчика' },
  { in: false, title: 'База знаний' },
  { in: false, title: 'Шаблоны' },
])

const CollapseItem = observer<{ collapse: CollapseProps }>(({ collapse }) => {
  const handleClick = action(() => {
    collapses.forEach((c) => (c.in = c.title === collapse.title))
  })

  return (
    <>
      <CollapseButton onClick={handleClick}>
        <Observer>
          {() => <CollapseIcon icon={'arrow'} data-is-open={collapse.in} />}
        </Observer>
        {collapse.title}
      </CollapseButton>
      <Observer>
        {() => (
          <Collapse
            unmountOnExit
            in={collapse.in}
            children={collapse.children}
          />
        )}
      </Observer>
    </>
  )
})

export const Collapses = () => (
  <Paper>
    {collapses.map((c) => (
      <CollapseItem key={c.title} collapse={c} />
    ))}
  </Paper>
)
