import { t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { Divider, Paper, Stack, Typography } from '@mui/material'
import { Checkboxes } from './checkboxes'
import { Properties } from './properties'

export const HowMarkup = observer(() => (
  <Stack flex={1} gap={2}>
    <Typography
      children={t`HowToMarkup`}
      variant={'h2'}
      color={'text.disabled'}
    />
    <Paper variant={'block'}>
      <Typography
        children={t`HowToMarkup`}
        variant={'subtitle1'}
        mx={3}
        my={1}
      />

      <Divider />

      <Checkboxes />

      <Divider />

      <Properties />
    </Paper>
  </Stack>
))
