import { observer } from 'mobx-react-lite'
import { useKeycloak } from '@react-keycloak/web'

import { token } from 'features/token'
import { ReactComponent as Logo } from 'assets/logo.svg'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import messagesEn from 'shared/locales/en/messages.json'
import messagesRu from 'shared/locales/ru/messages.json'

import * as Atom from './atoms'
import { Avatar } from './avatar'
import * as Routes from './routes'
import { Navigation } from './navigation'
import PrivateRoute from './PrivateRoutes'
import { NotAuthRedirect } from './routes'

i18n.load('en', messagesEn)
i18n.load('ru', messagesRu)
i18n.activate('en')

export const App = observer(() => {
  const { keycloak } = useKeycloak();

  if (keycloak.authenticated === undefined) return null
  console.log('keycloak', keycloak)

  if (keycloak.token) {
    token.set(keycloak.token)
  }

  return !keycloak.authenticated ? (
    <NotAuthRedirect />
  ) : (
    <Atom.Template>
      <I18nProvider i18n={i18n}>
        <Atom.Toolbar>
          <Logo />
          <Navigation />
          <Avatar />
        </Atom.Toolbar>
          <PrivateRoute>
            <Routes.Auth />
          </PrivateRoute>
      </I18nProvider>
    </Atom.Template>
  )
})
