import { runInAction } from 'mobx'
import { getData, patchData } from 'api'
import { getDatasetId, getProjectId } from 'utils'
import { catalogs, dataset, record, validationError } from './store'
import {
  ResponseDataset as RD,
  ResponseRecords as RR,
  ResponseCatalogs as RC,
  ResponsePatchDataset as RP,
} from 'types'
import { configObject } from './computed'
import { setPatch } from './action'
import { t } from '@lingui/macro'

export const getDataset = () =>
  getData(`/api/audio_dataset/${getDatasetId()}/`).on('response', (res: RD) => {
    if (res.error) return

    runInAction(() => dataset.set(res.body))
  })

export const getRecords = () =>
  getData(`/api/audio_records/`)
    .query({ dataset_id: getDatasetId(), page_size: 1 })
    .on('response', (res: RR) => {
      if (res.error) return
      const { results } = res.body

      runInAction(() => record.set(results[0]))
    })

export const getCategories = () =>
  getData('/api/audio_category/')
    .query({ project_id: getProjectId() })
    .on('response', (res: RC) => {
      if (res.error) return

      const { results } = res.body

      runInAction(() => catalogs.replace(results.map((c) => [c.id, c])))
    })

// Отправка дополнительных настроек датасета
type BodyReq = {
  name?: string
  priority?: number
  markup_duration?: string
  number_of_markups?: number
}
export const patchDatasetSettings = () => {
  const ds = dataset.get()
  const name = ds?.name
  const priority = ds?.priority
  const markup_duration = ds?.markup_duration
  const number_of_markups = ds?.number_of_markups

  const body: BodyReq = {}
  if (name) body.name = name;
  if (priority) body.priority = priority;
  if (markup_duration) body.markup_duration = markup_duration;
  if (number_of_markups) body.number_of_markups = number_of_markups;

  return (
    patchData(`/api/audio_dataset/${getDatasetId()}/`)
    .send(body)
    .on('response', (res: RP) => {
      if (res.error) {
        const { error: errors } = res.body
        let errorMessage = ''
        if (errors) {
          errorMessage = JSON.stringify(errors)
        }

        validationError.set(`${validationError.get()}\n\n${errorMessage}`)
      }
    })
  )
}

export const patchConfig = () =>
  patchData(`/api/audio_dataset/${getDatasetId()}/state/configure/`)
    .send({ config: configObject.get(), status: 2 })
    .on('response', (res: RP) => {
      setPatch(false)
      if (res.error) {
        const { error: errors } = res.body
        const error = errors ? errors[0] : undefined
        if (error?.code === 'E_INVALID_DATASET_STATE') {
          validationError.set(t`ErrorMessageStopMarkup`)
        } else {
          validationError.set(error?.message || '')
        }
        return
      }
      const { response, validation_err_msg } = res.body
      const ds = dataset.get()
      if (ds) {
        dataset.set({
          ...ds,
          state: 2,
        })
      }

      runInAction(() => {
        if (ds) ds.config = response.config
        validationError.set(validation_err_msg)
      })
    })
