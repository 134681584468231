import { t } from '@lingui/macro'
import { MenuItem } from '@mui/material'

import { StyledTextField } from '../styled-textfield'


type DateFilterProps = {
  value: string
  values: any[]
  onSelect: (value: any, name: string) => void
}

export const DateFilter = ({ values, value, onSelect }: DateFilterProps) => (
  <StyledTextField
    select
    fullWidth
    value={value}
    name={'created_at'}
    label={t`CreationDate`}
    onChange={(evt) => onSelect(evt.target.value, 'created_at')}
  >
    {values.map((el) => (
      <MenuItem key={el} value={el}>
        {el}
      </MenuItem>
    ))}
  </StyledTextField>
)
