import { action } from 'mobx'
import { t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { MenuItem, Skeleton, TextField } from '@mui/material'
import { dataValue, dataValueItems, loading } from 'features/settings-dataset'

export const DataValue = observer(() => {
  if (loading.get()) return <Skeleton variant={'button'} />

  return (
    <TextField
      label={t`DataField`}
      select
      value={dataValue.get()}
      onChange={action((e) => dataValue.set(e.target.value))}
    >
      {dataValueItems.get().map((i) => (
        <MenuItem key={i} value={i} children={i} />
      ))}
    </TextField>
  )
})
