import { t } from '@lingui/macro'
import { Checkbox, FormControlLabel } from '@mui/material'
import { Box } from '@mui/system'

import { InputSeconds } from '../atoms/input-seconds'


type CheckboxWithInputSeconsProps = {
  onChangeValue: (type: string, value: string) => void
  onChange: (
    type:
      | 'deleteSilenсe'
      | 'slice'
      | 'text'
      | 'addPunсtuation'
      | 'convertDigits'
      | 'addTranslate'
  ) => void
  seconds: string
  slice: boolean
}

export const CheckboxWithInputSeconds = ({
  onChange,
  onChangeValue,
  seconds,
  slice,
}: CheckboxWithInputSeconsProps) => (
  <Box>
    <FormControlLabel
      label={t`SlicingByLength`}
      control={<Checkbox onChange={() => onChange('slice')} checked={slice} />}
    />
    <InputSeconds
      seconds={Number(seconds)}
      onChange={(value: string) => {
        onChangeValue('seconds', value)
      }}
      slice={slice}
    />
  </Box>
)
