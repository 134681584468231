import { observer } from 'mobx-react-lite'
import { useState, MouseEvent } from 'react'
import { CloudUpload, Download } from '@mui/icons-material'
import {
  Button,
  FormControlLabel,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material'

import { usePage } from 'features/datasets'
import { GridRow, Cells, GridCell, Icon } from 'shared'
import { CreateHoneypotDataset } from 'features/smart-buttons/create-honeypot'

import { headCells } from './grid-cells'
import { CountSelected, ChboxHead } from './grid-atoms'
import { t } from '@lingui/macro'
import { createCell } from 'utils'


const Selected = observer(() => {
  const page = usePage()

  const [anchorElHoneypot, setAnchorElHoneypot] =
    useState<null | HTMLElement>(null)
  const [anchorElDownload, setAnchorElDownload] =
    useState<null | HTMLElement>(null)

  const [isConsensus, setIsConsensus] = useState(false)

  const handleOpen = (
    event: MouseEvent<HTMLButtonElement>,
    honeypot?: boolean
  ) =>
    honeypot
      ? setAnchorElHoneypot(event.currentTarget)
      : setAnchorElDownload(event.currentTarget)
  const handleClose = (honeypot?: boolean) =>
    honeypot ? setAnchorElHoneypot(null) : setAnchorElDownload(null)

  const allOrNot =
    page.allDatasets?.length === page.selected.length
      ? ' '+t`All`
      : page.selected.length > 1
      ? ' '+t`Selected`
      : ''

  return (
    <GridRow data-head>
      <Cells cells={headCells.slice(0, 1)} />
      <GridCell
        sx={{
          gridColumn: '2 / -1',
          gap: 2,
          placeContent: 'center start',
        }}
      >
        <Typography>{t`Selected2`}:</Typography>
        <Typography width={'5ch'}>
          <CountSelected />
        </Typography>
        <Button
          children={t`DownloadMarkup`}
          startIcon={<Download />}
          onClick={(evt) => handleOpen(evt)}
        />
        <Menu
          anchorEl={anchorElDownload}
          open={Boolean(anchorElDownload)}
          onClose={() => handleClose()}
        >
          <MenuItem>
            <FormControlLabel
              label={t`PriorityMarkup`}
              control={
                <Switch
                  checked={isConsensus}
                  onChange={() => setIsConsensus((prev) => !prev)}
                />
              }
            />
          </MenuItem>
          <MenuItem onClick={() => page.downloadMarkup('zip', isConsensus)}>
            <Download />
            {t`DownloadMarkupIn`} .zip {t`Format`}
          </MenuItem>
          <MenuItem onClick={() => page.downloadMarkup('mnf', isConsensus)}>
            <Download />
            {t`DownloadMarkupIn`} .mnf {t`Format`}
          </MenuItem>
          {page.allDatasets[0].type === 'IMAGE' && (
            <>
              <MenuItem
                onClick={() => page.downloadMarkup('coco', isConsensus)}
              >
                <Download />
                {t`DownloadMarkupIn`} COCO {t`Format`}
              </MenuItem>
              <MenuItem
                onClick={() => page.downloadMarkup('yolo', isConsensus)}
              >
                <Download />
                {t`DownloadMarkupIn`} YOLO {t`Format`}
              </MenuItem>
            </>
          )}
        </Menu>
        <Button
          children={t`CreateHoneypots`}
          startIcon={<CloudUpload />}
          onClick={(evt) => handleOpen(evt, true)}
          disabled={!page.noHoneypotSelected.length}
        />
        <Menu
          anchorEl={anchorElHoneypot}
          open={Boolean(anchorElHoneypot)}
          onClose={() => handleClose(true)}
        >
          {page.noHoneypotSelected.map((dataset) => (
            <CreateHoneypotDataset
              key={dataset.id}
              title={dataset.name}
              onSubmit={(value, isFactor) => {
                page.createHoneypotDataset(
                  dataset,
                  `${dataset.name}_Honeypot`,
                  value,
                  isFactor
                )
                handleClose(true)
              }}
              max={dataset.total.records || 0}
            />
          ))}
        </Menu>
        <Button
          children={t`TransferToHadoop`}
          startIcon={<CloudUpload />}
          onClick={() => page.exportToHadoop()}
        />
        <Button
          children={t`Remove` + allOrNot}
          startIcon={<Icon icon={'remove'} />}
          onClick={() => page.onRemoveAll()}
        />
      </GridCell>
    </GridRow>
  )
})

export const GridHead = observer(() => {
  const page = usePage()
  const head = [
    createCell(<ChboxHead />),
    createCell(null, { width: '1fr', placeContent: 'center' }),
    createCell(t`Type`, { width: '1fr', placeContent: 'center' }),
    createCell(t`Title`, { width: '10fr' }),
    createCell(t`Status`, { width: '3fr', placeItems: 'center' }),
    createCell(t`Total`, { width: '2fr', placeContent: 'center end' }),
    createCell(t`Done`, { width: '2fr', placeContent: 'center end' }),
    createCell(t`Skipped2`, { width: '2fr', placeContent: 'center end' }),
    createCell(t`CreationDate`, { width: '2fr', placeContent: 'center' }),
    createCell(null),
  ]

  if (page.hasSelected) return <Selected />

  return (
    <GridRow data-head>
      <Cells cells={head} />
    </GridRow>
  )
})
