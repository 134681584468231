import { t } from '@lingui/macro'
import { TableCell, TableRow, Typography } from '@mui/material'


export const NotFoundMarkups = () => (
  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
    <TableCell colSpan={6}>
      <Typography>{t`MarkupsNotFound`}</Typography>
    </TableCell>
  </TableRow>
)
