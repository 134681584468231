import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { MenuItem, Skeleton, TextField } from '@mui/material'
import { loading, dataType } from 'features/settings-dataset'
import { t } from '@lingui/macro'

export const DataType = observer(() => {
  if (loading.get()) return <Skeleton variant={'button'} />

  const items = [
    {
      type: 'FILE_OBJECT', 
      name: t`File`, 
    },
    {
      type: 'JSON_PATH', 
      name: t`StructuredFile`, 
    }
  ]

  return (
    <TextField
      select
      label={t`DataType`}
      value={dataType.get()}
      onChange={action((e) => dataType.set(e.target.value))}
    >
      {items.map((i) => (
        <MenuItem key={i.type} value={i.type} children={i.name} />
      ))}
    </TextField>
  )
})
