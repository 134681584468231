import { observer } from 'mobx-react-lite'
import { Box, Typography } from '@mui/material'
import { Page } from 'shared'
import { PageProvider } from 'features/projects'
import { ButtonNewProject } from './buttons'
import { ProjectList, NewProject } from './projects'
import { PageLoader } from './loaders'
import { t } from '@lingui/macro'

export const Projects = observer(() => (
  <PageProvider>
    <Page>
      <Typography 
        variant={'h1'}
        children={t`Projects`}
      />
      <ButtonNewProject />
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          position: 'relative',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          alignContent: 'start',
          gap: 3,
          m: -2,
          p: 2,
        }}
      >
        <NewProject />
        <ProjectList />
        <PageLoader />
      </Box>
    </Page>
  </PageProvider>
))
