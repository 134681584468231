import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { Checkbox, FormControlLabel, Skeleton } from '@mui/material'
import { loading, category, configObject } from 'features/settings-dataset'

import { makeProperties } from './utils'
import { t } from '@lingui/macro'

type RecordTextSelectionProps = {
  projectType: 'TEXT' | 'AUDIO' | 'IMAGE' | 'VIDEO' | undefined
}


export const RecordTextSelection = observer(
  ({ projectType }: RecordTextSelectionProps) => {
    const properties =
      configObject.get()?.markup_components?.classes?.ROOT?.properties
    const [record, setRecord] = useState(false)
    const [transcription, setTranscription] = useState(false)

    useEffect(() => {
      if (properties && Object?.keys(properties).includes('record'))
        setRecord(true)
      if (properties && Object?.keys(properties).includes('transcription'))
        setTranscription(true)
    }, [properties])

    const handle = (type: string) => {
      switch (type) {
        case 'record':
          setRecord((prev) => !prev)
          makeProperties(!record, 'record', configObject.get())
          break
        case 'transcription':
          setTranscription((prev) => !prev)
          makeProperties(!transcription, 'transcription', configObject.get())
          break
      }
    }

    useEffect(() => {
      if (!category.get()) {
        setRecord(false)
        setTranscription(false)
      }
    }, [category.get()])

    if (loading.get()) return <Skeleton variant={'checkbox'} />

    return (
      <>
        <FormControlLabel
          sx={{
            display: projectType?.toLowerCase() === 'text' ? 'inline' : 'none',
          }}
          label={t`SpeechToText`}
          control={
            <Checkbox
              checked={transcription}
              onChange={() => handle('transcription')}
            />
          }
        />

        <FormControlLabel
          sx={{
            display: projectType?.toLowerCase() === 'text' ? 'inline' : 'none',
          }}
          label={t`RecordAudio`}
          control={
            <Checkbox checked={record} onChange={() => handle('record')} />
          }
        />
      </>
    )
  }
)
