import { projectType } from "app/constants";
import { t } from '@lingui/macro'


export const getTypeColor = (type: string) => {
  switch (type) {
    case projectType.TEXT: return 'success';
    case projectType.AUDIO: return 'warning';
    case projectType.IMAGE: return 'secondary';
    case projectType.VIDEO: return 'info';
    default: return 'standard';
  }
}

export const getTypeColorHex = (type: string) => {
  switch (type) {
    case projectType.TEXT: return '#7cb342';
    case projectType.AUDIO: return '#ff9800';
    case projectType.IMAGE: return '#673ab7';
    case projectType.VIDEO: return '#1976d2';
    default: return '#0000008a';
  }
}

export const getTypeHumanName = (type: string) => {
  switch (type) {
    case projectType.TEXT: return t`Text`;
    case projectType.AUDIO: return t`Audio`;
    case projectType.IMAGE: return t`Pictures`;
    case projectType.VIDEO: return t`Video`;
    default: return '#0000008a';
  }
}
