import { observer } from 'mobx-react-lite'
import { Cells, GridRow } from 'shared'
import { styled } from '@mui/material'
import { createCell } from 'utils'
import { Head } from './checkboxes'
import { t } from '@lingui/macro'

export const GridHead = observer(() => {
  const Span = styled('span')(({ theme }) => ({
    paddingLeft: theme.spacing(1.5),
  }))
  
  const head = [
    createCell(<Head />, { width: 60 }),
    createCell(t`Color`, { placeContent: 'center', width: 50 }),
    createCell(null, { placeContent: 'center', width: 60 }),
    createCell(<Span>{t`Title`}</Span>, {
      placeItems: 'center stretch',
      width: '1fr',
    }),
    createCell(t`Catalog`, { placeContent: 'center end', width: 80 }),
    createCell(null, { placeItems: 'center stretch', width: 50 }),
    createCell(<Span>{t`Description`}</Span>, {
      placeItems: 'center stretch',
      width: '1fr',
    }),
    createCell('ID', { placeItems: 'center', width: 60 }),
    createCell(null, { placeContent: 'center end', width: 60 }),
  ]

  return (
    <GridRow data-head>
      <Cells cells={head} />
    </GridRow>
  )
})
